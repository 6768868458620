import React, { useState, useEffect } from "react";
import { X } from "lucide-react";
import { FaReact, FaJs, FaNodeJs, FaPython, FaDocker } from "react-icons/fa";
import { SiTypescript, SiMongodb } from "react-icons/si";
import { GrMysql } from "react-icons/gr";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../components/ui/card";
import { Badge } from "../components/ui/badge";
import ImageHolo from "./hhholographic.jpeg";
import BlackScholesPlot from "./blackScholes";


const techIcons = [
  { name: "React", Icon: FaReact },
  { name: "JavaScript", Icon: FaJs },
  { name: "TypeScript", Icon: SiTypescript },
  { name: "Node.js", Icon: FaNodeJs },
  { name: "Python", Icon: FaPython },
  { name: "Docker", Icon: FaDocker },
  { name: "MySQL", Icon: GrMysql },
  { name: "MongoDB", Icon: SiMongodb },
];

const TechCarousel = () => {
  const iconWidth = 100;
  const gap = 16;
  const totalWidth = techIcons.length * (iconWidth + gap);

  return (
    <div className="overflow-hidden mb-8 bg-gray-100 dark:bg-[hsl(0,0%,20%)] p-4 rounded-lg">
      <style>{`
        @keyframes scroll {
          0% { transform: translateX(0); }
          100% { transform: translateX(-${totalWidth}px); }
        }
      `}</style>
      <div
        className="flex"
        style={{
          animation: `scroll ${techIcons.length * 3}s linear infinite`,
          width: `${totalWidth * 2}px`,
        }}
      >
        {[...techIcons, ...techIcons].map((tech, index) => (
          <div
            key={index}
            className="flex flex-col items-center justify-center mx-2 flex-shrink-0"
            style={{ width: `${iconWidth}px` }}
          >
            <tech.Icon className="text-4xl mb-2" />
            <div className="text-sm">{tech.name}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

const portfolioItems = [
  {
    id: 1,
    title: "Black Scholes Model",
    description: "A visualization of the Black Scholes options pricing model. The calculations are done via a FastAPI backend and rendered using Plotly.js.",
    content: <BlackScholesPlot/ >,
    size: "large",
    skills: ["Python", "FastAPI", "Plotly", "Options Pricing", "Finance"],
    image: ImageHolo,
  }
];

const PortfolioCard = ({ item, onClick, delay }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, delay);

    return () => clearTimeout(timer);
  }, [delay]);

  const sizeClasses = {
    small: "col-span-4 row-span-4",
    medium: "col-span-4 row-span-8",
    large: "col-span-8 row-span-4",
  };

  const renderContent = () => {
    if (typeof item.content === "string") {
      return (
        <p className="group-hover:text-gray-900 dark:group-hover:text-gray-100 transition-colors duration-300">
          {item.content.substring(0, 100)}...
        </p>
      );
    } else if (React.isValidElement(item.content)) {
      return <div className="text-center py-4"></div>;
    }
    return null;
  };

  return (
    <Card
      className={`group cursor-pointer transition-all hover:shadow-lg hover:-translate-y-1 duration-300 ease-in-out  ${
        sizeClasses[item.size]
      } ${isVisible ? "opacity-100" : "opacity-0"} overflow-hidden flex`}
      style={{ transitionProperty: "opacity, box-shadow, transform" }}
      onClick={() => onClick(item)}
    >
      <div className="flex-grow flex flex-col p-4 ">
        <CardHeader className="p-0 mb-4">
          <CardTitle className="text-2xl font-semibold">
            <ShinyText>{item.title}</ShinyText>
          </CardTitle>
          <CardDescription className="text-sm text-gray-500 group-hover:text-gray-700 dark:group-hover:text-gray-300 transition-colors duration-300">
            {item.description}
          </CardDescription>
        </CardHeader>
        <CardContent className="p-0 mb-4 flex-grow">
          {renderContent()}
        </CardContent>
        <CardFooter className="p-0">
          <div className="flex flex-wrap gap-2">
            {item.skills.map((skill, skillIndex) => (
              <Badge key={skillIndex} variant="secondary">
                {skill}
              </Badge>
            ))}
          </div>
        </CardFooter>
      </div>
      {item.image && (
        <div className="relative w-1/3 flex items-center justify-center overflow-hidden">
          <img
            src={item.image}
            alt={item.title}
            className="w-40 h-40 object-cover"
          />
        </div>
      )}
    </Card>
  );
};

const PortfolioOverlay = ({ item, onClose }) => (
  <div className="fixed inset-0 bg-black bg-opacity-20 dark:bg-opacity-70 flex items-center justify-center p-4 z-[9999]">
    <Card className="w-full max-w-5xl max-h-[90vh] overflow-y-auto">
      <CardHeader>
        <CardTitle className="text-2xl sm:text-4xl font-light mb-4 sm:mb-6 dark:text-white pr-8">
          <ShinyText>{item.title}</ShinyText>
        </CardTitle>
        <CardDescription>{item.description}</CardDescription>
      </CardHeader>
      <CardContent>
        {typeof item.content === "string" ? (
          <p className="text-lg sm:text-xl dark:text-gray-300">
            {item.content}
          </p>
        ) : (
          React.isValidElement(item.content) && item.content
        )}
      </CardContent>
      <CardFooter>
        <div className="flex flex-wrap gap-2">
          {item.skills.map((skill, skillIndex) => (
            <Badge key={skillIndex} variant="secondary">
              {skill}
            </Badge>
          ))}
        </div>
      </CardFooter>
    </Card>
    <button
      onClick={onClose}
      className="fixed top-4 right-4 text-white hover:text-gray-300 transition-colors z-[10000] bg-[hsl(0,0%,20%)] bg-opacity-50 hover:bg-opacity-75 rounded-full p-2"
      aria-label="Close overlay"
    >
      <X size={24} />
    </button>
  </div>
);

const ShinyText = ({ children }) => <span className="shiny">{children}</span>;

const PortfolioGrid = () => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    setIsLoaded(true);

    const handleEscKey = (event) => {
      if (event.key === "Escape") {
        setSelectedItem(null);
      }
    };

    document.addEventListener("keydown", handleEscKey);

    return () => {
      document.removeEventListener("keydown", handleEscKey);
    };
  }, []);

  useEffect(() => {
    if (selectedItem) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [selectedItem]);

  return (
    <div className="min-h-screen transition-colors duration-300">
      <style jsx global>{`
        .shiny {
          background-image: linear-gradient(
            90deg,
            rgba(167, 199, 231, 0.9) 0%,
            rgba(69, 242, 252, 0.9) 100%
          );
          background-position: 0 -100%;
          background-size: 200% auto;
          background-clip: text;
          -webkit-background-clip: text;
          color: transparent;
          transition: background-position 1000ms ease-out;
        }
        .group:hover .shiny {
          background-position: 100% 100%;
        }
      `}</style>
      <div className="container mx-auto p-4 sm:p-8">
        <TechCarousel />
        <div className="grid grid-cols-2 sm:grid-cols-4 md:grid-cols-8 gap-4 sm:gap-8 auto-rows-fr">
          {portfolioItems.map((item, index) => (
            <PortfolioCard
              key={item.id}
              item={item}
              onClick={setSelectedItem}
              delay={isLoaded ? index * 100 : 0}
            />
          ))}
        </div>
        {selectedItem && (
          <PortfolioOverlay
            item={selectedItem}
            onClose={() => setSelectedItem(null)}
          />
        )}
      </div>
    </div>
  );
};

export default PortfolioGrid;
