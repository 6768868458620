import { useState, useEffect } from 'react';
import Plotly from 'plotly.js-dist';
import { Badge } from 'components/ui/badge';
import { Input } from 'components/ui/input';
import { Label } from 'components/ui/label';
import hljs from 'highlight.js';
import { CopyBlock, dracula } from 'react-code-blocks';

const BlackScholesPlot = () => {
  const [activeTab, setActiveTab] = useState('strike');
  const [plotData, setPlotData] = useState(null);
  const [params, setParams] = useState({
    S: 100,
    K: 100,
    r: 0.05,
    t: 1,
    sigma: 0.2,
    points: 50,
    option_type: 'call'
  });

  const handleParamChange = (param, value) => {
    // Convert percentage inputs to decimals
    if (param === 'r' || param === 'sigma') {
      value = parseFloat(value) / 100;
    } else {
      value = parseFloat(value);
    }
    
    if (!isNaN(value)) {
      setParams(prev => ({ ...prev, [param]: value }));
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const queryParams = {
          ...params,
          r: params.r,
          sigma: params.sigma
        };

        const response = await fetch(
          `https://eddgeza.pythonanywhere.com/black_scholes/range_asset_vs_${activeTab}?` + 
          new URLSearchParams(queryParams)
        );
        const data = await response.json();
        setPlotData(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    
    fetchData();
  }, [params, activeTab]);

  useEffect(() => {
    if (plotData) {
      const data = [{
        type: 'surface',
        x: plotData.matrix[0].slice(1),
        y: plotData.matrix.slice(1).map(row => row[0]),
        z: plotData.matrix.slice(1).map(row => row.slice(1)),
        colorscale: 'Viridis',
        showscale: false
      }];

      const layout = {
        scene: {
          yaxis: { title: 'Stock Price' },
          xaxis: { title: activeTab === 'strike' ? 'Strike Price' : 
                         activeTab === 'IV' ? 'Implied Volatility' : 'Time to Maturity' },
          zaxis: { title: 'Option Price' }
        },
        autosize: true,
        margin: { l: 0, r: 0, b: 0, t: 0 },
        height: 500,
        paper_bgcolor: 'rgba(0,0,0,0)',
        plot_bgcolor: 'rgba(0,0,0,0)',
        font: {
          color: 'rgb(156, 163, 175)'
        }
      };

      const config = {
        responsive: true,
        displayModeBar: true,
        displaylogo: false
      };

      Plotly.newPlot('plotDiv', data, layout, config);
    }
  }, [plotData, activeTab]);

  return (
    <div className="w-full h-full flex flex-col">
      <div className="flex gap-2 mb-4">
        <Badge
          variant={activeTab === 'strike' ? "default" : "secondary"}
          className="cursor-pointer"
          onClick={() => setActiveTab('strike')}
        >
          Strike Price
        </Badge>
        <Badge
          variant={activeTab === 'IV' ? "default" : "secondary"}
          className="cursor-pointer"
          onClick={() => setActiveTab('IV')}
        >
          Implied Volatility
        </Badge>
        <Badge
          variant={activeTab === 'ttm' ? "default" : "secondary"}
          className="cursor-pointer"
          onClick={() => setActiveTab('ttm')}
        >
          Time to Maturity
        </Badge>
      </div>
      
      <div id="plotDiv" className="w-full h-[500px]" />
      
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-4 mt-4">
        <div className="flex flex-col gap-2">
          <Label htmlFor="stock-price">Stock Price</Label>
          <Input
            id="stock-price"
            type="number"
            value={params.S}
            onChange={(e) => handleParamChange('S', e.target.value)}
            className="w-full"
          />
        </div>
        
        <div className="flex flex-col gap-2">
          <Label htmlFor="strike-price">Strike Price</Label>
          <Input
            id="strike-price"
            type="number"
            value={params.K}
            onChange={(e) => handleParamChange('K', e.target.value)}
            className="w-full"
          />
        </div>
        
        <div className="flex flex-col gap-2">
          <Label htmlFor="interest-rate">Interest Rate (%)</Label>
          <Input
            id="interest-rate"
            type="number"
            value={params.r * 100}
            onChange={(e) => handleParamChange('r', e.target.value)}
            className="w-full"
          />
        </div>
        
        <div className="flex flex-col gap-2">
          <Label htmlFor="time">Time to Maturity (y)</Label>
          <Input
            id="time"
            type="number"
            value={params.t}
            onChange={(e) => handleParamChange('t', e.target.value)}
            className="w-full"
          />
        </div>
        
        <div className="flex flex-col gap-2">
          <Label htmlFor="volatility">Volatility (%)</Label>
          <Input
            id="volatility"
            type="number"
            value={params.sigma * 100}
            onChange={(e) => handleParamChange('sigma', e.target.value)}
            className="w-full"
          />
        </div>
      </div>
      <div className="mt-8 space-y-4 text-gray-600 text-sm">
        <div className="bg-gray-50 p-4 rounded-lg">
          <p className="font-medium mb-2">Black-Scholes Formula:</p>
          <div className="space-y-2">
            <p>For a call option:</p>
            <p className="font-mono">C(S,t) = SN(d₁) - Ke⁻ʳᵗN(d₂)</p>
            <p>For a put option:</p>
            <p className="font-mono">P(S,t) = Ke⁻ʳᵗN(-d₂) - SN(-d₁)</p>
            <p>Where:</p>
            <p className="font-mono">d₁ = (ln(S/K) + (r + σ²/2)t) / (σ√t)</p>
            <p className="font-mono">d₂ = d₁ - σ√t</p>
          </div>
        </div>
        <p>
          Parameters:
          <span className="block mt-2 ml-4">• Stock Price (S): Current market price of the underlying asset</span>
          <span className="block ml-4">• Strike Price (K): Price at which the option can be exercised</span>
          <span className="block ml-4">• Interest Rate (r): Risk-free rate, expressed as a percentage</span>
          <span className="block ml-4">• Time to Maturity (t): Time until option expiration in years</span>
          <span className="block ml-4">• Volatility (σ): Expected price volatility, expressed as a percentage</span>
        </p>
        <p>
          The model won the Nobel Prize in Economics in 1997 and is widely used in the finance industry to price options. This is the latest major breakthrough in the field of finance since the publication of the Black-Scholes model in 1973.
          A few improvements have been made to the model since then, but the basic principles remain the same, and the model is still widely used today. One significant impact of the model is that we trade options based on implied volatility. 
          As the models pricing is based on the unkown future volatility of the underlying asset, thus we can estimate the market's expectation of future volatility by looking at the price of the option.
        </p>
        <CopyBlock
        text={`
import numpy as np 
from fastapi import FastAPI 
from scipy.stats import norm 
import math

app = FastAPI()

def black_scholes(S, K, r, t, sigma, option_type):
    d1 = (math.log(S / K) + (r + (sigma ** 2) / 2) * t) / (sigma * math.sqrt(t))
    d2 = d1 - sigma * math.sqrt(t)
    if option_type == "call":
        return S * norm.cdf(d1) - K * math.exp(-r * t) * norm.cdf(d2)
    elif option_type == "put":
        return K * math.exp(-r * t) * norm.cdf(-d2) - S * norm.cdf(-d1)
    else:
        return "Invalid option type"

#This function will process a range of data to help us visualize the option price's dependency on strike and asset price.
@app.get("/black_scholes/range_asset_vs_strike")
async def calculate_option_range(
    option_type: str, 
    S: float,  # Base stock price
    K: float,  # Base strike price
    r: float, 
    t: float, 
    sigma: float,
    points: int = 10,  # Number of points per dimension
    range_multiplier: float = 2.0
):
    try:
        # Create arrays for both stock and strike prices
        prices = np.linspace(min(S, K)/2, max(S, K)*range_multiplier, points)
        
        # Initialize the matrix
        # First row will contain strike prices (y values)
        # First column will contain stock prices (x values)
        matrix = np.zeros((points + 1, points + 1))
        
        # Set the strike prices in the first row (excluding first cell)
        matrix[0, 1:] = prices
        
        # Set the stock prices in the first column (excluding first cell)
        matrix[1:, 0] = prices
        
        # Calculate option prices for the matrix
        for i in range(points):
            for j in range(points):
                stock_price = prices[i]
                strike_price = prices[j]
                matrix[i + 1, j + 1] = black_scholes(
                    stock_price, 
                    strike_price, 
                    r, 
                    t, 
                    sigma, 
                    option_type
                )
        
        #df = pd.DataFrame(matrix)
        #print(df)
        return {
            "parameters": {
                "option_type": option_type,
                "base_stock_price": S,
                "base_strike_price": K,
                "risk_free_rate": r,
                "time_to_expiry": t,
                "volatility": sigma
            },
            "matrix": matrix.tolist()  # Convert to list for JSON serialization
        }
    except ValueError as e:
        return {"error": str(e)}`}
          language={"python"}
          showLineNumbers={false}
          theme={dracula}
          wrapLines
          />
      </div>
    </div>
  );
};

export default BlackScholesPlot;