import { useState, useEffect } from "react";

const greetings = [
  "Hello", // English
  "Hej", // Swedish
  "Bonjour", // French
  "Guten Tag", // German
  "Ciao", // Italian
  "こんにちは", // Japanese
  "你好", // Mandarin Chinese
  "안녕하세요", // Korean
  "Hola", // Spanish
  "Olá", // Portuguese
  "Merhaba", // Turkish
  "नमस्ते", // Hindi
  "Salam", // Arabic
  "Γεια σας", // Greek
  "Aloha", // Hawaiian
  "Sawubona", // Zulu
  "Jambo", // Swahili
  "Xin chào", // Vietnamese
  "Hallå", // Swedish (informal)
];

const LoadingPage = () => {
  const [currentGreeting, setCurrentGreeting] = useState(0);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const interval = 200; // 0.2 seconds

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentGreeting((prev) => (prev + 1) % greetings.length);
    }, interval);

    // Check for dark mode preference
    const darkModeQuery = window.matchMedia("(prefers-color-scheme: dark)");
    setIsDarkMode(darkModeQuery.matches);

    // Listen for changes in color scheme preference
    const darkModeListener = (e: MediaQueryListEvent) =>
      setIsDarkMode(e.matches);
    darkModeQuery.addEventListener("change", darkModeListener);

    return () => {
      clearInterval(intervalId);
      darkModeQuery.removeEventListener("change", darkModeListener);
    };
  }, []);

  const bgColor = isDarkMode ? "bg-[hsl(0,0%,15%)]" : "bg-gray-100";
  const textColor = isDarkMode ? "text-white" : "text-black";

  return (
    <div
      className={`flex items-center justify-center h-screen ${bgColor} transition-colors duration-300`}
    >
      <div className="text-center">
        <h1 className={`text-4xl font-light ${textColor}`}>
          {greetings[currentGreeting]}
        </h1>
      </div>
    </div>
  );
};

export default LoadingPage;
